import { useEffect, useState } from 'react';
import $ from 'jquery';

import { useTawkContext } from '../TawkContext';

import logo from '../../assets/images/logo.svg';

import { STANDARD_ROUTE_URIS } from '../../routes/Router';

import Travel from './Travel';
import { useBetween } from 'use-between';

const useCurrencyState = () => {
	//by default AUD is use, one day will add a GEOIP detection to change this
    const [currency, setCurrency] = useState("AUD");
    const [currencySign, setCurrencySign] = useState("$");
    const [currencyId, setCurrencyId] = useState("1");
	
    const currencyToIdMap = {
        'AUD': '1',
        'USD': '4',
        // Add other currencies here eventually, to match the WHMCS ID
    };

    useEffect(() => {
        let sign;
        switch (currency) {
            case 'USD':
            case 'AUD':
            case 'CAD':
                sign = '$';
                break;
            case 'EUR':
                sign = '€';
                break;
            case 'GBP':
                sign = '£';
                break;
            case 'JPY':
                sign = '¥';
                break;
            default:
                sign = '';
        }
        setCurrencySign(sign);
		setCurrencyId(currencyToIdMap[currency]);
    }, [currency]);

    return {
        currency,
        setCurrency,
        currencySign,
		currencyId
    };
};

export const useSharedCurrencyState = () => useBetween(useCurrencyState);

export default function HeroBanner() {
    const { currency, setCurrency, currencySign } = useSharedCurrencyState();
	
	const { handleMaximize } = useTawkContext();

    useEffect(() => {
        $(window).scroll(function(){
            if ($(window).scrollTop() >= 39) {
                $('.header').addClass('fixed-header');
				$('.top-bar').addClass('fixed-top-bar');
                $('#main-navigation').addClass('open-nav-fix');
            }
            else {
                $('.header').removeClass('fixed-header');
				$('.top-bar').removeClass('fixed-top-bar');
                $('#main-navigation').addClass('open-nav-fix');
            }
        });

        setInterval(function time(){
            var d = new Date();
            var hours: string | number = 24 - d.getHours();
            var min: string | number = 60 - d.getMinutes();
            if((min + '').length == 1){
            min = '0' + `${min}`;
            }
            var sec: string | number = 60 - d.getSeconds();
            if((sec + '').length == 1){
                sec = '0' + `${sec}`;
            }
            if((hours + '').length == 1){
                hours = '0' + `${hours}`;
            }

            $('#clock').html('<span>0</span> : <span>' + hours+'</span> : <span>'+min+'</span> : <span>'+sec+'</span>')
            }, 1000);

            if ($(window).width() < 991 || $(window).height() < 480) {
              $(window).scroll(function(){
                if ($(window).scrollTop() >= 1) {
                    $('.header').addClass('fixed-header');
					$('.top-bar').addClass('fixed-top-bar');
                    $('#main-navigation').addClass('open-nav-fix');
                }
                else {
                    $('.header').removeClass('fixed-header');
					$('.top-bar').removeClass('fixed-top-bar');
                    $('#main-navigation').addClass('open-nav-fix');
                }
            });
            }

            $('#toggle').on('click', function() {
                $(this).toggleClass('active');
                $('#main-navigation').toggleClass('open-nav');
				$('.header').toggleClass('header-black');
            });

            return () => {
                $('#toggle').off('click');
            };
    });

    return (
        <>
            <div className="top-bar">
                <div className="container">
                    <div className="row">
                    <div className="col banner-tagline">
						<a style={{color: 'white'}} href="https://billing.streamline-servers.com/cart.php?a=add&pid=411&promocode=SATISFY1"><strong className="mr-1"><i className="fa-regular fa-sparkles mr-1" style={{color: '#4478f2'}}></i>Satisfactory 1.0!</strong>Get <strong className="green">25%</strong> off <strong className="green">Satisfactory</strong> servers with Promo Code <strong className="green">SATISFY1</strong> during checkout</a>
                    </div>
                    <div className="col ml-auto">
                        <ul className="pull-right" id="top-bar-nav">
							<li className="inline-block mr-4">
								<a className="dropdown-toggle" href="javascript:void(0);" id="navbarLogin" data-bs-toggle="dropdown" data-bs-auto-close="*" aria-expanded="false">
									<i className="fa-solid fa-life-ring mr-1"></i> SUPPORT<i className="fa-regular fa-chevron-down ml-1 font-11 va-mid"></i>
								</a>
								<div className="dropdown-menu dropdown-menu-dark" aria-labelledby="navbarLogin">
									<ul>
										<li><a href="javascript:void(0);" onClick={handleMaximize} className="dropdown-item">Sales Live Support</a></li>
										<li><a href="https://billing.streamline-servers.com/submitticket.php?step=2&deptid=1" className="dropdown-item">Support Ticket</a></li>
										<li><a href="https://status.streamline-servers.com/" target="_blank" className="dropdown-item">Network Status</a></li>
									</ul>
								</div>
							</li>
							<li className="inline-block mr-4">
								<a className="dropdown-toggle" href="javascript:void(0);" id="navbarLogin" data-bs-toggle="dropdown" data-bs-auto-close="*" aria-expanded="false">
									<i className="fa-solid fa-lock mr-1"></i> LOGIN<i className="fa-regular fa-chevron-down ml-1 font-11 va-mid"></i>
								</a>
								<div className="dropdown-menu dropdown-menu-dark" aria-labelledby="navbarLogin">
									<ul>
										<li><a href="https://billing.streamline-servers.com" className="dropdown-item">Billing</a></li>
										<li><a href="https://streamlinepanel.com" className="dropdown-item">Game Panel</a></li>
									</ul>
								</div>
							</li>
							<li className="inline-block">
								<a className="dropdown-toggle" href="javascript:void(0);" id="navbarCurrency" data-bs-toggle="dropdown" data-bs-auto-close="*" aria-expanded="false">
									{currencySign} {currency}<i className="fa-regular fa-chevron-down ml-1 font-11 va-mid"></i>
								</a>
								<div className="dropdown-menu dropdown-menu-dark" aria-labelledby="navbarCurrency">
									<ul>
										<li><a className="dropdown-item" style={{cursor: 'pointer'}} onClick={(e) => setCurrency('AUD')}>$ AUD</a></li>
									</ul>
									<ul>
										<li><a className="dropdown-item" style={{cursor: 'pointer'}} onClick={(e) => setCurrency('USD')}>$ USD</a></li>
									</ul>
								</div>
							</li>
                        </ul>
                    </div>
                    </div>
                </div>
                </div>
                <header className="header">
                <div className="container">
                    <div className="row">
                    <div className="col col-auto">
                        <Travel to={STANDARD_ROUTE_URIS.INDEX}><img src={logo} className="logo"/></Travel>
                    </div>
                    <div className="col ml-auto">
                        <ul id="main-navigation">
                        <li><Travel to={STANDARD_ROUTE_URIS.GAME_SERVERS}>Game<span>Servers</span></Travel></li>
                        <li className="divider"></li>
                        <li><Travel to={STANDARD_ROUTE_URIS.CLOUD}>Cloud<span>Servers</span></Travel></li>
                        <li className="divider"></li>
                        <li><Travel to={STANDARD_ROUTE_URIS.DEDICATED}>Dedicated<span>Servers</span></Travel></li>
                        <li className="divider"></li>
                        <li><Travel to={STANDARD_ROUTE_URIS.VOICE_SERVERS}>Voice<span>Servers</span></Travel></li>
                        <li className="divider"></li>
                        <li><Travel to={STANDARD_ROUTE_URIS.WEB_HOSTING}>Web<span>Hosting</span></Travel></li>
                        <li className="divider"></li>
                        <li className="inline-block">
                            <a className="dropdown-toggle" href="javascript:void(0);" id="navbarAbout" data-bs-toggle="dropdown" data-bs-auto-close="*" aria-expanded="false">More<span>Information<i className="fa-solid fa-chevron-down ml-1"></i></span></a>
                            <div className="dropdown-menu dropdown-menu-dark" aria-labelledby="navbarAbout">
                            <ul>
                                <li><Travel to={STANDARD_ROUTE_URIS.ABOUT} className="dropdown-item">About Us</Travel></li>
                                <li><Travel to={STANDARD_ROUTE_URIS.AFFILIATES} className="dropdown-item">Affiliates</Travel></li>
                                <li><Travel to={STANDARD_ROUTE_URIS.LEGAL} className="dropdown-item">Legal</Travel></li>
                            </ul>
                            </div>
                        </li>
                        <li><a href="https://billing.streamline-servers.com/register.php" className="button"><strong>SIGN UP</strong></a></li>
                        </ul>
                        <div className="button_container" id="toggle">
                            <span className="top"></span>
                            <span className="middle"></span>
                            <span className="bottom"></span>
                        </div>
                    </div>
                    </div>
                </div>
                </header>
        </>
    );

}