import React, { useState, useEffect } from 'react';
import Container from '../layouts/Core/Container';

import WidgetBot from '@widgetbot/react-embed'

import { useTawkContext } from '../layouts/TawkContext';

import gslMark from '../assets/images/globalsecurelayer-mark.svg'

import Map from '../layouts/Map';
import MapWithList from '../layouts/MapWithList';
import Travel from '../layouts/Core/Travel';
import { Reviews, TrustPilot } from '../layouts/TrustPilot';
import { Fade, Nav, Tab } from 'react-bootstrap';
import { useSharedCurrencyState } from '../layouts/Core/HeroBanner';

// Function to shuffle array for Reviews
function shuffleArray(array: any[]) {
  for (let i = array.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

// Function to chunk array for Reviews
function chunkArray<T>(myArray: T[], chunk_size: number): T[][]{
  let index = 0;
  let arrayLength = myArray.length;
  let tempArray: T[][] = [];
  
  for (index = 0; index < arrayLength; index += chunk_size) {
    const myChunk = myArray.slice(index, index+chunk_size);
    tempArray.push(myChunk);
  }
  
  return tempArray;
}

 export default function IndexPage() {
 
	const [randomReviews, setRandomReviews] = useState<Array<Array<typeof Reviews[number]>>>([]);
	const { currency } = useSharedCurrencyState();
	
	const { handleMaximize } = useTawkContext();
	
	  useEffect(() => {
		const shuffledReviews = shuffleArray([...Reviews]).slice(0, 9);
		setRandomReviews(chunkArray(shuffledReviews, 3));
	  }, []);

    const TABS = {
        BENEFIT: {
            ONE: 'a',
            TWO: 'b',
            THREE: 'c'
        },
        PANEL: {
            DASHBOARD: 'dashboard',
            UPDATES: 'update',
            CONFIG: 'config',
            MODS: 'mods',
            CONSOLE: 'console'
        },
        MAP: {
            REGULAR: 'regular',
            LIST: 'list'
        }
    };

    const [panelTab, setPanelTab] = useState(TABS.PANEL.DASHBOARD);
    const [mapTab, setMapTab] = useState(TABS.MAP.REGULAR);

	const [imageClicked, setImageClicked] = useState(false);

	const handleImageClick = () => {
		setImageClicked(true);
	};

	const handleOverlayClick = () => {
		setImageClicked(false);
	};

    return (
        <Container title='Streamline Servers - Home' description='Hosting Quality Servers since 2007. Built on a World Class Backbone Network with superior DDoS Protection and connectivity.' className='page-home'>
            <section className="hero-home" id="hero">
                <div id="carouselHomePage" className="carousel slide" data-bs-ride="carousel" data-interval="20">
                    <div className="carousel-inner">
                    <div className="carousel-item active">
                        <div className="hero-bgs satisfactory"></div>
                        <div className="container">
							<div className="carousel-max-width">
								<h1 style={{fontSize: '40px'}}>Satisfactory <span className="satisfactory-text">1.0</span></h1>
								<h2>Get <span className='green font-un'>25%</span> off with Coupon <span className='green font-un'>SATISFY1</span></h2>
								{currency === "AUD" ? (
								  <h3 className="green py-3">Starting at <span className='line-through font-14'>$17.20</span> $12.9 per month</h3>
								) : currency === "USD" ? (
								  <h3 className="green py-3">Starting at <span className='line-through font-14'>$12.92</span> $9.69 per month</h3>
								) : (
								  <h3 className="green py-3">Starting at <span className='line-through font-14'>$17.20</span> $12.9 per month</h3>
								)}
								<p className="font-22">
								New Update! You will finally be able to flush the toilet in the HUB!
								<br />
								Get <span className='green font-un'>25%</span> off for <span className='green font-un'>3</span> months on a new Satisfactory server!
								</p>
								<a href="https://billing.streamline-servers.com/cart.php?a=add&pid=411&promocode=SATISFY1" className="button large mt-2">Create Your Server <i className="fa-solid fa-arrow-right-long ml-1"></i></a>
							</div>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <div className="hero-bgs palworld"></div>
                        <div className="container">
							<div className="carousel-max-width">
								<h1 style={{fontSize: '40px'}}>Palworld Update: <span className="palworld-text">Sakurajima</span></h1>
								<h2><span className='green font-un'>Now Out!</span></h2>
								{currency === "AUD" ? (
								  <h3 className="green py-3">Starting at $32.00 per month</h3>
								) : currency === "USD" ? (
								  <h3 className="green py-3">Starting at $24.00 per month</h3>
								) : (
								  <h3 className="green py-3">Starting at $32.00 per month</h3>
								)}
								<p className="font-22">
								New Pals, New Island, new Boss and so much more!
								<br />
								Instantly create your own <span className='green font-un'>Palworld</span> server today!
								</p>
								<a href="/game-servers/palworld" className="button large mt-2">Create Your Server <i className="fa-solid fa-arrow-right-long ml-1"></i></a>
							</div>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <div className="hero-bgs dedi-1"></div>
                        <div className="container">
							<div className="carousel-max-width">
								<h1 style={{fontSize: '40px'}}>Sydney Intel Xeon E3-1280v5</h1>
								<h2><span className='green font-un'>Now in Stock!</span></h2>
								{currency === "AUD" ? (
								  <h3 className="green py-3">Starting at $150.00 per month</h3>
								) : currency === "USD" ? (
								  <h3 className="green py-3">Starting at $112.50 per month</h3>
								) : (
								  <h3 className="green py-3">Starting at $150.00 per month</h3>
								)}
								<p className="font-22">
								Introducing Sydney Intel Xeon E3-1280v5 Dedicated Servers! These affordable Entry Servers will not last long, order today!
								</p>
								<a href="https://billing.streamline-servers.com/cart.php?a=add&pid=441" className="button large mt-2">Configure Now <i className="fa-solid fa-arrow-right-long ml-1"></i></a>
							</div>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <div className="hero-bgs thefront"></div>
                        <div className="container">
							<div className="carousel-max-width">
								<h1>The Front</h1>
								<h2>Can you survive this new world?</h2>
								{currency === "AUD" ? (
								  <h3 className="green py-3">Starting at $20.00 per month</h3>
								) : currency === "USD" ? (
								  <h3 className="green py-3">Starting at $15.00 per month</h3>
								) : (
								  <h3 className="green py-3">Starting at $20.00 per month</h3>
								)}
								<p className="font-22">
								Collect resources, craft tech, build shelters, and fight monsters to accomplish your mission.
								</p>
								<Travel to="/game-servers/the-front" className="button large mt-2">Create Your Server <i className="fa-solid fa-arrow-right-long ml-1"></i></Travel>
							</div>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <div className="hero-bgs cs2"></div>
                        <div className="container">
							<div className="carousel-max-width">
								<h1>Counter-Strike 2</h1>
								<h2>The next era of Counter-Strike is here!</h2>
								{currency === "AUD" ? (
								  <h3 className="green py-3">Starting at $10.00 per month</h3>
								) : currency === "USD" ? (
								  <h3 className="green py-3">Starting at $7.50 per month</h3>
								) : (
								  <h3 className="green py-3">Starting at $10.00 per month</h3>
								)}
								<p className="font-22">
								Counter-Strike 2 is the largest technical leap forward in CS history, ensuring new features and updates for years to come.
								</p>
								<Travel to="/game-servers/counter-strike-2" className="button large mt-2">Create Your Server <i className="fa-solid fa-arrow-right-long ml-1"></i></Travel>
							</div>
                        </div>
                    </div>
                    </div>

                    <div className="container">
                    <div className="hero-slider-right-content">
                        <img src={TrustPilot.trustpilotLogoHome} className="block ml-auto" />
                        <img src={TrustPilot.trustpilotStarsHome} className="block ml-auto mt-3 mb-2" />
                        <span className="font-13 block mb-4">TrustScore {TrustPilot.TrustScore} &nbsp; | &nbsp; {TrustPilot.Reviews} reviews &nbsp; | &nbsp; <a href={TrustPilot.URL} target="_blank">Read Reviews</a></span>
                        <a style={{cursor: 'pointer'}} className="hero-nav hero-nav-prev mr-2" data-bs-target="#carouselHomePage" data-bs-slide="prev"><i className="fa-solid fa-chevrons-left"></i></a>
                        <a style={{cursor: 'pointer'}} className="hero-nav hero-nav-next" data-bs-target="#carouselHomePage" data-bs-slide="next"><i className="fa-solid fa-chevrons-right"></i></a>
                    </div>

                    <div className="carousel-indicators">
                        <div className="row">
                        <div className="col">
                            <a data-bs-target="#carouselHomePage" data-bs-slide-to="0" className="active" aria-current="true"  aria-label="Slide 1"><img src={require('../assets/images/game-indicator-satisfactory.webp')}/></a>
                        </div>
                        <div className="col">
                            <a data-bs-target="#carouselHomePage" data-bs-slide-to="1" aria-label="Slide 2"><img src={require('../assets/images/game-indicator-palworld-sakurajima.webp')}/></a>
                        </div>
                        <div className="col">
                            <a data-bs-target="#carouselHomePage" data-bs-slide-to="2" aria-label="Slide 3"><img src={require('../assets/images/game-indicator-dedi.webp')}/></a>
                        </div>
                        <div className="col">
                            <a data-bs-target="#carouselHomePage" data-bs-slide-to="3" aria-label="Slide 4"><img src={require('../assets/images/game-indicator-thefront.webp')}/></a>
                        </div>
                        <div className="col">
                            <a data-bs-target="#carouselHomePage" data-bs-slide-to="4" aria-label="Slide 5"><img src={require('../assets/images/game-indicator-cs2.webp')}/></a>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </section>

            <section id="home-intro-tabs" className="pt-4">
                <div className="container">
                    <div className="row relative">
                        <Tab.Container defaultActiveKey={TABS.BENEFIT.TWO}>
                            <div className="home-intro-tab-bg"></div>
                            <div className="col col-lg-5 offset-xl-1 hidemobile">
                                    <Nav role='tablist' style={{display: 'block'}} >
                                        <Nav.Item>
                                            <Nav.Link eventKey={TABS.BENEFIT.ONE}>
                                                <div className="icon-relative">
                                                    <i className="fa-solid fa-shield font-48 mr-3 pull-left grad-behind-icon"></i>
                                                    <i className="fa-thin fa-shield font-48 mr-3 pull-left"></i>
                                                </div>
                                                <strong className="block font-18">Surperior DDoS Protection</strong>
                                                <span className="font-14">Bulletproof Defense, Safeguarding your Servers</span>  
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className='ml-5'>
                                            <Nav.Link eventKey={TABS.BENEFIT.TWO}>
                                                <div className="icon-relative">
                                                    <i className="fa-solid fa-life-ring font-48 mr-3 pull-left grad-behind-icon"></i>
                                                    <i className="fa-thin fa-life-ring font-48 mr-3 pull-left"></i>
                                                </div>
                                                <strong className="block font-18">Unparalleled Live Support</strong>
                                                <span className="font-14">Assisting Gamers with Exceptional Live Support</span>
                                            </Nav.Link>                        
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey={TABS.BENEFIT.THREE}>
                                                <div className="icon-relative">
                                                    <i className="fa-solid fa-earth-americas font-48 mr-3 pull-left grad-behind-icon"></i>
                                                    <i className="fa-thin fa-earth-americas font-48 mr-3 pull-left"></i>
                                                </div>
                                                <strong className="block font-18">Global Expanding Network</strong>
                                                <span className="font-14">A Growing Network, Reaching Gamers Everywhere</span>
                                            </Nav.Link>                        
                                        </Nav.Item>
                                    </Nav>
                            </div>
                            <div className="col col-lg-7 col-xl-5 pr-5 prfix pt-5 ptfix">
                                <h2>Streamline-Servers<br />Online Gaming Since <Travel to="/about">2007</Travel></h2>
                                <Tab.Content className="pt-2">
                                    <Tab.Pane eventKey={TABS.BENEFIT.ONE} role="tabpanel">
                                        <div className="icon-relative inline-block showmobile">
                                        <i className="fa-solid fa-shield va-mid font-55 mb-3 green-icon grad-behind-icon"></i>
                                        <i className="fa-thin fa-shield va-mid font-55 mb-3 green-icon"></i>
                                        </div>
                                        <h3 className="font-22 pt-4 pb-2">Setting the Industry Standard</h3>
                                        <p className="pb-0">
                                        Fortify your game servers and dedicated infrastructure with our world-class DDoS protection, in collaboration with our esteemed partner, <a href="https://globalsecurelayer.com" target="_blank">Global Secure Layer</a>. Experience unparalleled security, empowering your online endeavors with confidence and reliability.
                                        </p>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey={TABS.BENEFIT.TWO} role="tabpanel">
                                        <div className="icon-relative inline-block showmobile">
                                        <i className="fa-solid fa-life-ring va-mid font-55 mb-3 green-icon grad-behind-icon"></i>
                                        <i className="fa-thin fa-life-ring va-mid font-55 mb-3 green-icon"></i>
                                        </div>
                                        <h3 className="font-22 pt-4 pb-2">Serving Customers Round the Clock</h3>
                                        <p className="pb-0">
                                       We provide top-notch assistance that has been consistently praised as the absolute best for both gamers and businesses. We take great pride in our support team, who are always here to ensure your satisfaction and success in every interaction. You can expect exceptional support service that goes above and beyond.
                                        </p>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey={TABS.BENEFIT.THREE} role="tabpanel">
                                        <div className="icon-relative inline-block showmobile">
                                        <i className="fa-solid fa-earth-americas va-mid font-55 mb-3 green-icon grad-behind-icon"></i>
                                        <i className="fa-thin fa-earth-americas va-mid font-55 mb-3 green-icon"></i>
                                        </div>
                                        <h3 className="font-22 pt-4 pb-2">A Location for Everyone</h3>
                                        <p className="pb-0">
                                        At Streamline-Servers, our mission is to continuously expand our global reach, connecting with diverse gamers and businesses across continents. We strive to empower and elevate their experiences by providing top-notch services and unmatched support.
                                        </p>
                                    </Tab.Pane>
                                </Tab.Content>
                            </div>
                        </Tab.Container>
                    </div>
                </div>
            </section>

            <section className="pt-5" id="home-control-panel">
                <div className="container">
                    <Tab.Container defaultActiveKey={TABS.PANEL.DASHBOARD}>
                        <div className="clearfix mb-5 relative">
                            <h2 className="pull-left"><small>OUR CONTROL PANEL</small>Easy-to-Use & Powerful <br />Game Panel</h2>
                            <div className="tab-line-start"></div>
                            <div className="home-panel-tabs text-center pull-right pt-4">
                                <Nav role='tablist' className="icon-text-tabs d-block">
                                    <Nav.Item>
                                        <Nav.Link eventKey={TABS.PANEL.DASHBOARD} >
                                        <div className="icon-relative">
                                            <i className="fa-solid fa-gauge-simple-max font-44 mb-1 grad-behind-icon grad-behind-icon-center"></i>
                                            <i className="fa-thin fa-gauge-simple-max font-44 mb-1"></i>
                                        </div>
                                        <span className="block">Dashboard</span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey={TABS.PANEL.UPDATES} >
                                        <div className="icon-relative">
                                            <i className="fa-solid fa-download font-44 mb-1 grad-behind-icon grad-behind-icon-center"></i>
                                            <i className="fa-thin fa-download font-44 mb-1"></i>
                                        </div>
                                        <span className="block">Easy Updates</span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey={TABS.PANEL.CONFIG} >
                                        <div className="icon-relative">
                                            <i className="fa-solid fa-pen-to-square font-44 mb-1 grad-behind-icon grad-behind-icon-center"></i>
                                            <i className="fa-thin fa-pen-to-square font-44 mb-1"></i>
                                        </div>
                                        <span className="block">Config Editor</span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey={TABS.PANEL.MODS} >
                                        <div className="icon-relative">
                                            <i className="fa-solid fa-cubes font-44 mb-1 grad-behind-icon grad-behind-icon-center"></i>
                                            <i className="fa-thin fa-cubes font-44 mb-1"></i>
                                        </div>
                                        <span className="block">Mod Manager</span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey={TABS.PANEL.CONSOLE}>
                                        <div className="icon-relative">
                                            <i className="fa-solid fa-square-terminal font-44 mb-1 grad-behind-icon grad-behind-icon-center"></i>
                                            <i className="fa-thin fa-square-terminal font-44 mb-1"></i>
                                        </div>
                                        <span className="block">Web Console</span>
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>
                        </div>

                        <Tab.Content id="intro-tabs-tabContent">
                            <Tab.Pane eventKey={TABS.PANEL.DASHBOARD} role="tabpanel">
                                <div className="row">
									<div className={`col col-lg-6 ${imageClicked ? 'blur-background active' : 'blur-background'}`}>
										{imageClicked && <div className='overlay' onClick={handleOverlayClick} />}
										<img
											src={require('../assets/images/game-panel-1.webp')}
											className={`responsive-img ${imageClicked ? 'slide-fade' : ''}`}
											onClick={handleImageClick}
										/>
										{imageClicked && (
											<img
												src={require('../assets/images/game-panel-1-full.webp')}
												className='responsive-img enlarged'
												onClick={handleOverlayClick}
											/>
										)}
									</div>
                                    <div className="col col-lg-5 ml-auto pr-5 prfix pt-5 ptfix">
                                        <h2 className="pt-3">Modern and Functional</h2>
                                        <p className="pt-2">
                                        Immerse yourself in the essence of modernity with our game panel, designed to be sleek and adaptable with its dark and light modes. Effortlessly access advanced features that cater to users of all levels, whether you're a beginner or a seasoned expert.
                                        </p>
                                        <Travel to="/game-servers" className="button">View Game Servers</Travel>
                                    </div>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey={TABS.PANEL.UPDATES} role="tabpanel">
                                <div className="row">
									<div className={`col col-lg-6 ${imageClicked ? 'blur-background active' : 'blur-background'}`}>
										{imageClicked && <div className='overlay' onClick={handleOverlayClick} />}
										<img
											src={require('../assets/images/game-panel-4.webp')}
											className={`responsive-img ${imageClicked ? 'slide-fade' : ''}`}
											onClick={handleImageClick}
										/>
										{imageClicked && (
											<img
												src={require('../assets/images/game-panel-4-full.webp')}
												className='responsive-img enlarged'
												onClick={handleOverlayClick}
											/>
										)}
									</div>

                                    <div className="col col-lg-5 ml-auto pr-5 prfix pt-5 ptfix">
                                        <h2 className="pt-3">Always stay Updated</h2>
                                        <p className="pt-2">
                                        Keep your game server up-to-date effortlessly with our user-friendly game panel. With just one click, you can directly download updates from Steam servers. Moreover, our panel offers an Task Scheduler to allow automatic update feature for added convenience.
                                        </p>
                                        <Travel to="/game-servers" className="button">View Game Servers</Travel>
                                    </div>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey={TABS.PANEL.CONFIG} role="tabpanel">
                                <div className="row">
									<div className={`col col-lg-6 ${imageClicked ? 'blur-background active' : 'blur-background'}`}>
										{imageClicked && <div className='overlay' onClick={handleOverlayClick} />}
										<img
											src={require('../assets/images/game-panel-2.webp')}
											className={`responsive-img ${imageClicked ? 'slide-fade' : ''}`}
											onClick={handleImageClick}
										/>
										{imageClicked && (
											<img
												src={require('../assets/images/game-panel-2-full.webp')}
												className='responsive-img enlarged'
												onClick={handleOverlayClick}
											/>
										)}
									</div>

                                    <div className="col col-lg-5 ml-auto pr-5 prfix pt-5 ptfix">
                                        <h2 className="pt-3">Easy Customisation</h2>
                                        <p className="pt-2">
                                        No need to continuously upload and download your server configuration files, our game panel comes with in-built features that include a Configuration Editor, making it easier than ever to customise your server.
                                        </p>
                                        <Travel to="/game-servers" className="button">View Game Servers</Travel>
                                    </div>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey={TABS.PANEL.MODS} role="tabpanel">
                                <div className="row">
									<div className={`col col-lg-6 ${imageClicked ? 'blur-background active' : 'blur-background'}`}>
										{imageClicked && <div className='overlay' onClick={handleOverlayClick} />}
										<img
											src={require('../assets/images/game-panel-5.webp')}
											className={`responsive-img ${imageClicked ? 'slide-fade' : ''}`}
											onClick={handleImageClick}
										/>
										{imageClicked && (
											<img
												src={require('../assets/images/game-panel-5-full.webp')}
												className='responsive-img enlarged'
												onClick={handleOverlayClick}
											/>
										)}
									</div>

                                    <div className="col col-lg-5 ml-auto pr-5 prfix pt-5 ptfix">
                                        <h2 className="pt-3">Expanding Catalog of Mods</h2>
                                        <p className="pt-2">
                                        Our game panel extends mod installation options for various game servers. Seamlessly integrated into our panel is the Steam Workshop, enabling convenient one-click installs and updates. Additionally, we provide our own Mod Manager, which supports third-party mods.
										<span className="font-14 opacity-50 block">*Not available for all Game Servers</span>
                                        </p>
                                        <Travel to="/game-servers" className="button">View Game Servers</Travel>
                                    </div>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey={TABS.PANEL.CONSOLE} role="tabpanel">
                                <div className="row">
									<div className={`col col-lg-6 ${imageClicked ? 'blur-background active' : 'blur-background'}`}>
										{imageClicked && <div className='overlay' onClick={handleOverlayClick} />}
										<img
											src={require('../assets/images/game-panel-3.webp')}
											className={`responsive-img ${imageClicked ? 'slide-fade' : ''}`}
											onClick={handleImageClick}
										/>
										{imageClicked && (
											<img
												src={require('../assets/images/game-panel-3-full.webp')}
												className='responsive-img enlarged'
												onClick={handleOverlayClick}
											/>
										)}
									</div>

                                    <div className="col col-lg-5 ml-auto pr-5 prfix pt-5 ptfix">
                                        <h2 className="pt-3">Live Web Console</h2>
                                        <p className="pt-2">
                                        Our game panel provides a live console log for your game server, enabling real-time monitoring and tracking of server activities right from your browser. This interactive feature ensures you stay connected and informed about your server's operation.
                                        </p>
                                        <Travel to="/game-servers" className="button">View Game Servers</Travel>
                                    </div>
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </div>
            </section>

            <section id="home-locations" className="pt-0">
                <div className="container">
                    <Tab.Container defaultActiveKey={TABS.MAP.REGULAR}>
                        <div className="title">
                            <h2><small>Network Locations</small>A Location for Everyone</h2>
                            <p>
                                Our servers are currently available in the following international locations
                            </p>
                        </div>
                        <div className="text-center pb-5 mt-n2">
                            <Nav style={{display: 'block'}} role='tablist' className='lined-tabs'>
                                <Nav.Item className="inline-block px-3">
                                    <Nav.Link role="tab" eventKey={TABS.MAP.REGULAR}>
                                        Map Format
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item className="inline-block px-3">
                                    <Nav.Link role="tab" eventKey={TABS.MAP.LIST}>
                                        List Format        
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>
                        <Tab.Content>
                            <Tab.Pane eventKey={TABS.MAP.REGULAR} role="tabpanel">
                                <Map/>
                            </Tab.Pane>
                            <Tab.Pane eventKey={TABS.MAP.LIST} role="tabpanel">
                                <MapWithList/>
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </div>
            </section>

            <section id="testimonials-slider-boxes" className="py-4">
                <div className="container">
                    <div className="row first-row">
                    <div className="col col-lg-3 relative green-box hidetablet">
                        <img src={TrustPilot.trustpilotLogo} className="mt-3" />
                        <h4 className="font-20 mt-3 pt-2 pb-1 mb-1">{TrustPilot.TrustScore}/5.0 - "{TrustPilot.Rating}"</h4>
                        <img src={TrustPilot.trustpilotStars} />
                        <span className="font-13 abs-bottom"><strong>{TrustPilot.Reviews}</strong> Reviews | <a href={TrustPilot.URL} target="_blank">Read All <i className="fa-regular fa-arrow-up-right-from-square font-11"></i></a></span>
                    </div>
                    <div className="col col-md-12 col-lg-9 ml-auto" id="the-home-slider">
                        <div id="carouselHomeSlider" className="carousel slide" data-bs-ride="carousel" data-interval="25000">
							<div className="carousel-inner">
							  {randomReviews.map((reviewGroup, idx) => (
								<div key={idx} className={`carousel-item ${idx === 0 ? 'active' : ''}`}>
								  <div className="row">
									{reviewGroup.map((review, index) => (
									  <div key={index} className="col slider-boxes review-box box">
										<img src={TrustPilot.trustpilotStarsGreenSmall} className="block mb-2" />
										<strong className="font-18 block pb-2">{review.Name}</strong>
										<p className="review-text">{review.Review}</p>
										<span className="italic opacity-50">{review.Date}</span>
										<a className="font-14 ml-3 bold" href={review.URL} target="_blank">Read More...</a>
									  </div>
									))}
								  </div>
								</div>
							  ))}
							</div>
							<button className="carousel-control-prev" type="button" data-bs-target="#carouselHomeSlider" data-bs-slide="prev">
								<span className="carousel-control-prev-icon" aria-hidden="true"></span>
								<span className="visually-hidden">Previous</span>
							</button>
							<button className="carousel-control-next" type="button" data-bs-target="#carouselHomeSlider" data-bs-slide="next">
								<span className="carousel-control-next-icon" aria-hidden="true"></span>
								<span className="visually-hidden">Next</span>
							</button>
                        </div>
                    </div>
                    </div>
                </div>
            </section>

            <section className="features" id="home-features">
                <div className="container">
                    <div className="title">
                    <h2><small>Included Features</small>Enterprise Grade Hardware Backed by a <br />Powerful & Secure Network</h2>
                    </div>
                    <div className="row main-row mt-5 mtfix">
                    <div className="col main-col col-md-6 col-lg-4 has-divides">
                        <h3>
                                        <div className="icon-relative inline-block">
                                            <i className="fa-solid fa-hard-drive va-mid font-40 mr-3 green-icon grad-behind-icon"></i>
                                            <i className="fa-thin fa-hard-drive va-mid font-40 mr-3 green-icon"></i>
                                        </div><span className="m-block">NVMe SSD Storage</span>
                                    </h3>
                        <p className="pt-2">
                        We make sure to equip our Gaming Dedicated Servers with the newest and fastest NVMe drives available, so you can be rest assured your game server won't experience hardware throttling.
                        </p>
                    </div>
                    <div className="col main-col col-md-6 col-lg-4 has-divides">
                        <h3>
                                        <div className="icon-relative inline-block">
                                            <i className="fa-solid fa-chart-network va-mid font-40 mr-3 green-icon grad-behind-icon"></i>
                                            <i className="fa-thin fa-chart-network va-mid font-40 mr-3 green-icon"></i>
                                        </div><span className="m-block">10Gbit Network</span>
                                    </h3>
                        <p className="pt-2">
                        All our Gaming Dedicated Servers come packed with 10Gbit NICs (network cards) for lightning-fast network speeds. This means you'll always experience the best possible speeds.
                        </p>
                    </div>
                    <div className="col main-col col-md-6 col-lg-4">
                        <h3>
                                        <div className="icon-relative inline-block">
                                            <i className="fa-solid fa-microchip va-mid font-40 mr-3 green-icon grad-behind-icon"></i>
                                            <i className="fa-thin fa-microchip va-mid font-40 mr-3 green-icon"></i>
                                        </div><span className="m-block">Latest CPUs</span>
                                    </h3>
                        <p className="pt-2">
                        We go all-in with the latest CPUs from top brands like AMD and Intel. We ensure that you have cutting-edge processing power for the best possible game server hosting experience.
                        </p>
                    </div>
                    <div className="col main-col col-md-6 col-lg-6 has-divides">
                        <h3>
                                        <div className="icon-relative inline-block">
                                            <i className="fa-solid fa-shield-check va-mid font-40 mr-3 green-icon grad-behind-icon"></i>
                                            <i className="fa-thin fa-shield-check va-mid font-40 mr-3 green-icon"></i>
                                        </div><span className="m-block">DDoS Protection by <a href="https://globalsecurelayer.com/" className="white-link"><img src={gslMark} className="va-mid mr-1 gsl-logo" />Global Secure Layer</a></span>
                                    </h3>
                        <p className="pt-2">
                        We've partnered with Global Secure Layer for advanced DDoS protection, low-latency optimized routing and a global anycast network.
                        </p>
                        <div className="row">
                        <div className="col col-md-6">
                            <strong><i className="fa-light fa-circle-check mr-1"></i> Attack Detection and Mitigation </strong>
                            <p className="pt-1">
                            Advanced protection, adaptive security policies and rule sets for high mitigation accuracy.
                            </p>
                        </div>
                        <div className="col col-md-6">
                            <strong><i className="fa-light fa-circle-check mr-1"></i>{ "TTM < 1 second" }</strong>
                            <p className="pt-1">
                            With near-instant traffic diversion to start mitigation, protection starts in just under 1 second.
                            </p>
                        </div>
                        </div>
                    </div>
                    <div className="col main-col col-md-6 col-lg-6">
                        <h3>
							<div className="icon-relative inline-block">
								<i className="fa-solid fa-life-ring va-mid font-40 mr-3 green-icon grad-behind-icon"></i>
								<i className="fa-thin fa-life-ring va-mid font-40 mr-3 green-icon"></i>
							</div><span className="m-block">Live Support</span>
                        </h3>
                        <p className="pt-2">
                        We're renowned for our top-tier support, catering to all customers. Whether you're seeking guidance on game server locations or require help with mod installations, we've got your back.
                        </p>
                        <ul id="feature-support-methods-list">
                        <li className="inline-block pr-3">
                            <a href="https://billing.streamline-servers.com/submitticket.php?step=2&deptid=1" className="white-link"><i className="fa-light fa-envelope font-18 va-mid mr-1"></i> Contact Us</a>
                        </li>
                        <li className="inline-block pr-3">
                            <a href="javascript:void(0);" onClick={handleMaximize} className="white-link"><i className="fa-light fa-comments font-18 va-mid mr-1"></i> Sales Live Chat <span className="label ml-1">We're Online!</span></a>
                        </li>

                        </ul>
                        <span className="block font-14 italic opacity-50 mt-2">Average ticket response time: 22 Minutes</span>
                    </div>

                    </div>
                </div>
            </section>

            <section className="light-bg" id="home-community">
                <div className="container">
                    <div className="title">
                    <h2><small>STAY IN TOUCH</small>We’re Big on Community</h2>
                    <p>Join our Discord server to receive the latest news and promotions.</p>
                    </div>
					<div className="discordBox">
						<WidgetBot
							server="399103920686891008"
							channel="489345609254567936"
							height="700px"
							width="100%"
						/>
					</div>
                </div>
            </section>

            <section className="green-bg bg-cta-game-list" id="cta">
                <div className="container">
                    <div className="row">
                    <div className="col col-md-8 col-lg-6">
                        <h2>Deploy your game server!</h2>
                        <strong className="block pb-4 font-22">Not sure if this is right for you? Contact Us!</strong>
                        <ul className="cta-link-list">
                        <li className="inline-block pr-3">
                            <a href="https://billing.streamline-servers.com/submitticket.php?step=2&deptid=1" className="white-link"><i className="fa-solid fa-envelope font-16 mr-1"></i>Contact Us</a>
                        </li>
                        <li className="inline-block pr-3">
                            <a href="javascript:void(0);" onClick={handleMaximize} className="white-link"><i className="fa-solid fa-comments font-16 mr-1"></i> Sales Live Chat</a>
                        </li>
                        </ul>
                    </div>
                    <div className="col col-md-4 col-lg-6 text-center">
                        <Travel to="/game-servers" className="button large dark mt-5">Order Now <i className="fa-sharp fa-solid fa-arrow-right-long ml-1"></i></Travel>
                    </div>
                    </div>
                </div>
            </section>
        </Container>
     );
 }